import { lazy } from "react";

export const Landing = lazy(() => import("./Landing"));

export const Nursery1 = lazy(() => import("./Nursery1"));
export const Nursery2 = lazy(() => import("./Nursery2"));
export const KinderGarten = lazy(() => import("./Kinder"));
export const Elementary = lazy(() => import("./Elementary"));
export const JHSchool = lazy(() => import("./JHSchool"));
export const SHSchool = lazy(() => import("./SHSchool"));

export const Theology = lazy(() => import("./Theology"));
export const ChristianEducation = lazy(() => import("./ChristianEd"));

export const MastersDegree = lazy(() => import("./Masters"));
export const CertificateProgram = lazy(() => import("./CertProgram"));
export const SEEProgram = lazy(() => import("./SEEProgram"));

export * from "./Types";
export * from "./Theology";

export * from "./PDFRenderer";
