import React from "react";
import { View, Text } from "@react-pdf/renderer";

type Props = {
  heading: string;
  flexDirection?:
    | "row"
    | "row-reverse"
    | "column"
    | "column-reverse"
    | undefined;
};

export const Header: React.FC<Props> = ({
  heading,
  flexDirection,
  children,
}) => {
  return (
    <View
      style={{
        paddingBottom: 8,
      }}
      wrap={false}
    >
      <Text
        style={{
          fontWeight: 700,
          fontSize: 14,
          color: "#212121",
        }}
      >
        {heading}
      </Text>
      <View
        style={{
          flexDirection: flexDirection ? flexDirection : "column",
          justifyContent: "space-between",
          flexWrap: "wrap",
        }}
      >
        {children}
      </View>
    </View>
  );
};
