import styled from "styled-components/macro";
import media from "styles/media";

export const StyledLandingCtaWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 1.2rem;

  #master-select,
  #cert-program-select {
    grid-column: 1 / -1;
  }

  ${media.mobileMd} {
    grid-template-columns: 1fr;
    row-gap: 2rem;
  }
`;
