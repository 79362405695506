import styled from "styled-components/macro";
import { StyledInput } from "./input";

export const StyledTextArea = styled(StyledInput)`
  min-height: 24rem;
  max-height: 100rem;

  resize: none;

  font-family: Roboto, san-serif;
`;
