import styled from "styled-components/macro";
import { StyledFormInputContainer } from "styles";
import media from "styles/media";

export const StyledLearningWrapper = styled(StyledFormInputContainer)`
  #learning-error {
    grid-column: 1 / -1;
    color: var(--color-primary);
    font-family: Roboto;
    font-weight: 500;
    text-transform: uppercase;
  }

  ${media.tabletSm} {
    grid-template-columns: 1fr;
  }
`;
