import React from "react";
import ReactPDF, { View, Image, Text, StyleSheet } from "@react-pdf/renderer";
import spbtsLOGO from "assets/images/logo.png";

type Props = {
  heading: string;
  rightImageSrc: ReactPDF.SourceObject;
};

export const PageHeader: React.FC<Props> = ({ heading, rightImageSrc }) => {
  return (
    <View style={styles.header} fixed>
      <Image src={spbtsLOGO} style={styles.headerLogos} />
      <View style={{ alignItems: "center" }}>
        <Text
          style={{
            fontSize: 14,
            fontFamily: "Lora",
            color: "#8b0000",
          }}
        >
          Southern Philippines Baptist Theological Seminary, Inc.
        </Text>
        <Text
          style={{
            fontFamily: "Roboto",
            fontSize: 11,
            color: "#212121",
            paddingTop: 4,
          }}
        >
          {heading}
        </Text>
      </View>
      <Image src={rightImageSrc} style={styles.headerLogos} />
    </View>
  );
};

const styles = StyleSheet.create({
  header: {
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 28,
  },
  headerLogos: {
    width: 50,
    height: 50,
  },
});
