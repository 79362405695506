import emailJs from "emailjs-com";

export function useEmail<T>(templateID: string) {
  const sendEmail = async (templateParams: T) => {
    try {
      const result = await emailJs.send(
        `${process.env.REACT_APP_EMAIL_SERVICE_ID}`,
        templateID,
        templateParams,
        `${process.env.REACT_APP_EMAIL_USER_ID}`
      );
      return result;
    } catch (error) {
      return error;
    }
  };

  return sendEmail;
}
