import React from "react";
import {
  StyleSheet,
  Document,
  Page,
  View,
  Text,
  Font,
} from "@react-pdf/renderer";
import { PageHeader, Header, TextField, SubHeader } from "documents";
import {
  EnrolleeStatusProps,
  EducationalProps,
  PaymentProps,
  PersonalProps,
  TestimonyProps,
} from "routes";

import chedLOGO from "assets/images/ched.png";

import Lora700 from "assets/fonts/lora/lora-v15-latin-700.woff";
import Roboto400 from "assets/fonts/roboto/roboto-v20-latin-regular.woff";
import Roboto500 from "assets/fonts/roboto/roboto-v20-latin-500.woff";
import Roboto700 from "assets/fonts/roboto/roboto-v20-latin-700.woff";

type Props = EnrolleeStatusProps &
  EducationalProps &
  PaymentProps &
  PersonalProps &
  TestimonyProps;

const CollegeDocument: React.FC<Props> = (p) => (
  <Document title="College Pre-Admission Form">
    <Page size={{ width: 612, height: 936 }} style={styles.page}>
      <PageHeader
        heading="College Department | Pre-Admission Form"
        rightImageSrc={chedLOGO}
      />
      <View style={styles.content}>
        <View style={{ ...styles.contentContainer, marginRight: 20 }}>
          <Header heading="Enrollee Status">
            <TextField label="Student Type" value={p.typeOfStudent} />
            <TextField label="Semester" value={p.semester} />
            <TextField
              label="School Year"
              value={`${p.schoolYear.from} - ${p.schoolYear.to}`}
            />
            <TextField label="Desired Course" value={p.desiredCourse} />
            <TextField label="Year Level" value={p.yearLevel} />
            <View
              style={{
                paddingTop: 8,
                fontSize: 11,
                fontWeight: 500,
                color: "#333333",
              }}
            >
              <Text style={{ fontWeight: 400 }}>
                Name of School Last S.Y. Attended:
              </Text>
              <Text style={{ maxWidth: 240 }}>{p.schoolNameLastAttended}</Text>
            </View>
          </Header>

          <Header heading="Educational Background">
            <TextField label="Elementary" value={p.elementarySchoolName} />
            <TextField
              label="Year Graduated"
              value={p.elementaryYearGraduated}
            />
            <TextField label="Junior High" value={p.jhSchoolName} />
            <TextField label="Year Graduated" value={p.jhYearGraduated} />
            <TextField label="Senior High" value={p.shSchoolName} />
            <TextField label="Academic Track" value={p.shAcademicTrack} />
            <TextField label="Year Graduated" value={p.shYearGraduated} />

            <SubHeader subHeading="College Last Attended">
              <TextField label="School Name" value={p.collegeSchoolName} />
              <TextField label="Course Taken" value={p.collegeCourseTaken} />
              <TextField
                label="Year Graduated"
                value={p.collegeYearGraduated}
              />
            </SubHeader>
            <TextField label="Special Skills" value={p.specialSkills} />
          </Header>

          <Header heading="Payment Information">
            <TextField
              label="Payment Method"
              value={p.paymentMethod || p.paymentService || p.paymentBank}
            />
          </Header>
        </View>

        <View style={styles.contentContainer}>
          <Header heading="Personal Information">
            <TextField label="First Name" value={p.firstName} />
            <TextField label="Last Name" value={p.lastName} />
            <TextField label="Middle Name" value={p.middleName} />
            <TextField label="Suffix" value={p.suffix} />
            <TextField label="Gender" value={p.gender} />
            <TextField label="Civil Status" value={p.civilStatus} />
            <TextField label="Spouse Name [If Married]" value={p.spouseName} />
            <TextField label="Birth Place" value={p.birthPlace} />
            <TextField label="Birth Date" value={p.birthDate} />
            <TextField label="Age" value={p.age} />
            <TextField label="Nationality" value={p.nationality} />
            <TextField label="Dialect" value={p.dialect} />
            <TextField label="Ethnic Affliation" value={p.ethnicAffiliation} />
            <TextField label="Religion" value={p.religion} />
            <TextField label="Contact #" value={p.contactNumber} />
            <TextField label="Email Address" value={p.emailAddress} />
            <TextField label="Facebook Account" value={p.facebookAccount} />
            <TextField
              label="Home Address"
              value={
                p.homeCountryAddress
                  ? `${p.homeCountryAddress}, ${p.homeAddress}`
                  : p.homeAddress
              }
            />
            <TextField
              label="Parent's Annual Income"
              value={p.parentsAnnualIncome.annualIncome}
            />
            <TextField
              label="Other Income [Please specify]"
              value={p.parentsAnnualIncome.otherIncome}
            />
            <TextField
              label="Father's Name"
              value={p.parentsInfo.fathersName}
            />
            <TextField
              label="Occupation"
              value={p.parentsInfo.fathersOccupation}
            />
            <TextField
              label="Mother's Name"
              value={p.parentsInfo.mothersName}
            />
            <TextField
              label="Occupation"
              value={p.parentsInfo.mothersOccupation}
            />
            <SubHeader subHeading="Person Supporting You [Other than parents]">
              <TextField
                label="Name"
                value={p.otherPersonSupport?.completeName}
              />
              <TextField
                label="Occupation"
                value={p.otherPersonSupport?.occupation}
              />
              <TextField
                label="Address"
                value={p.otherPersonSupport?.address}
              />
              <TextField
                label="Relationship"
                value={p.otherPersonSupport?.relationship}
              />
            </SubHeader>
          </Header>
        </View>
      </View>
    </Page>

    <Page size={{ width: 612, height: 936 }} style={styles.page}>
      <PageHeader
        heading="College Department | Pre-Admission Form"
        rightImageSrc={chedLOGO}
      />
      <View style={styles.content2}>
        <Header
          heading={`${p.firstName} ${p.lastName} ${p.suffix}`.capitalize()}
        />
        <Header heading="Church Information" flexDirection="row">
          <View style={styles.wrapper}>
            <TextField
              label="Church Name"
              value={p.churchName}
              maxWidth="100%"
            />
            <TextField
              label="Date Baptized"
              value={p.dateBaptized}
              maxWidth="100%"
            />
          </View>

          <View style={styles.wrapper}>
            <TextField
              label="Church Address"
              value={p.churchAddress}
              maxWidth="100%"
            />
            <TextField
              label="Association"
              value={p.association}
              maxWidth="100%"
            />
          </View>

          <View style={styles.wrapper}>
            <TextField
              label="Pastor's Name"
              value={p.churchPastorName}
              maxWidth="100%"
            />
            <TextField
              label="Pastor's Contact #"
              value={p.churchPastorContactNumber}
              maxWidth="100%"
            />
          </View>
        </Header>

        <Header heading="Spiritual Autobiography">
          <SubHeader subHeading="Understanding of the Gospel" maxWidth="100%">
            <Text>{p.understandingOfGospel}</Text>
          </SubHeader>

          <SubHeader subHeading="Salvation Testimony" maxWidth="100%">
            <Text>{p.salvationTestimony}</Text>
          </SubHeader>

          <SubHeader subHeading="Purpose of Enrolling in SPBTS" maxWidth="100%">
            <Text>{p.purposeOfEnrolling}</Text>
          </SubHeader>
        </Header>
      </View>
    </Page>
  </Document>
);

Font.register({
  family: "Lora",
  fonts: [
    {
      src: Lora700,
      fontWeight: 700,
    },
  ],
});

Font.register({
  family: "Roboto",
  fonts: [
    {
      src: Roboto400,
      fontWeight: 400,
    },
    {
      src: Roboto500,
      fontWeight: 500,
    },
    {
      src: Roboto700,
      fontWeight: 700,
    },
  ],
});

const styles = StyleSheet.create({
  page: {
    padding: "32 40",
    lineHeight: "1.7",
  },
  content: {
    fontFamily: "Roboto",
    fontWeight: 400,
    flexDirection: "row",
  },
  contentContainer: {
    flexGrow: 1,
  },
  content2: {
    fontFamily: "Roboto",
    fontWeight: 400,
  },
  wrapper: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: "wrap",
    paddingBottom: 2,
  },
});

export default CollegeDocument;
