export const blobToBase64 = (
  blob: Blob
): Promise<string | ArrayBuffer | null> => {
  const reader = new FileReader();

  reader.readAsDataURL(blob);

  return new Promise((resolve) => {
    reader.onloadend = () => {
      if (reader.result) {
        resolve(reader.result.toString().split(",")[1]);
      }
    };
  });
};
