import React from "react";
import {
  StyleSheet,
  Document,
  Page,
  View,
  Text,
  Font,
} from "@react-pdf/renderer";
import { PageHeader, Header, TextField, SubHeader } from "documents";
import {
  EnrolleeStatusProps,
  PersonalProps,
  EducationalProps,
  TestimonyProps,
  OtherProps,
} from "routes";
import { transformDate } from "utils";

import chedLOGO from "assets/images/ched.png";

import Lora700 from "assets/fonts/lora/lora-v15-latin-700.woff";
import Roboto400 from "assets/fonts/roboto/roboto-v20-latin-regular.woff";
import Roboto500 from "assets/fonts/roboto/roboto-v20-latin-500.woff";
import Roboto700 from "assets/fonts/roboto/roboto-v20-latin-700.woff";

type Props = EnrolleeStatusProps &
  PersonalProps &
  EducationalProps &
  TestimonyProps &
  OtherProps;

const NotApplicable = "N/A";

const SEEProgramDocument: React.FC<Props> = (p) => (
  <Document title="S.E.E Program Application Form">
    <Page size={{ width: 612, height: 936 }} style={styles.page}>
      <PageHeader
        heading="S.E.E Program Application Form"
        rightImageSrc={chedLOGO}
      />
      <View style={styles.content}>
        <View style={{ ...styles.contentContainer, marginRight: 20 }}>
          <Header heading="Registration Information">
            <TextField label="Desired program" value={p.seeDesiredProgram} />
            <TextField
              label="Student status"
              value={p.otherStudentStatus || p.studentStatus}
            />
            <TextField label="Semester" value={p.semester} />
            <TextField label="Year level" value={p.yearLevel} />
            <TextField
              label="S.E.E Center Location"
              value={p.seeCenterLocation}
            />
            <TextField
              label="Name of Center Leader"
              value={p.seeCenterLeader}
            />
          </Header>

          <Header heading="Applicant Information">
            <TextField label="First name" value={p.firstName} />
            <TextField label="Last name" value={p.lastName} />
            <TextField label="Middle name" value={p.middleName} />

            <TextField label="Suffix" value={p.suffix || NotApplicable} />

            <TextField label="Gender" value={p.gender} />
            <TextField label="Civil status" value={p.civilStatus} />
            <TextField label="Birth place" value={p.birthPlace} />
            <TextField label="Birth date" value={p.birthDate} />
            <TextField label="Age" value={p.age} />
            <TextField label="Contact #" value={p.contactNumber} />
            <TextField label="Email address" value={p.emailAddress} />
            <TextField label="Facebook account" value={p.facebookAccount} />
            <TextField label="Current address" value={p.currentAddress} />
            <TextField label="Nationality" value={p.nationality} />
          </Header>

          <Header heading="Church Information">
            <TextField label="Church name" value={p.churchName} />
            <TextField label="Date of membership" value={p.dateOfMembership} />
            <TextField label="Address" value={p.churchAddress} />
            <TextField label="Pastor" value={p.churchPastorName} />

            <TextField
              label="Pastor's phone #"
              value={p.churchPastorContactNumber}
            />

            <View
              style={{
                paddingTop: 8,
                fontSize: 11,
                fontWeight: 400,
                color: "#333333",
              }}
            >
              <Text style={{ maxWidth: 250 }}>
                Is the church that holds your membership affiliated with the
                Southern Baptist Convention? &nbsp;
                <Text style={{ fontWeight: 500 }}>{p.isAffiliatedWithSBC}</Text>
              </Text>
            </View>

            <View
              style={{
                paddingTop: 8,
                fontSize: 11,
                fontWeight: 400,
                color: "#333333",
              }}
            >
              <Text style={{ maxWidth: 250 }}>
                If NO, what denominational affiliation? &nbsp;
              </Text>
              <Text style={{ fontWeight: 500 }}>
                {p.otherDenominationalAffiliation || NotApplicable}
              </Text>
            </View>
          </Header>
        </View>

        <View style={styles.contentContainer}>
          <Header heading="Personal Information">
            <TextField
              label="Spouse's name"
              value={
                p.civilStatus?.toLowerCase() !== "single"
                  ? `${p.spouseFirstName} ${p.spouseMiddleName} ${p.spouseLastName} ${p.spouseSuffix}`.trim()
                  : NotApplicable
              }
            />
            <TextField
              label="Date of marriage"
              value={p.dateOfMarriage || NotApplicable}
            />

            <Text
              style={{
                fontWeight: 500,
                fontSize: 10,
                color: "#333333",
                maxWidth: 250,
                paddingTop: 8,
                paddingBottom: 2,
                textTransform: "uppercase",
              }}
            >
              Children: {p.children?.length ? "" : "NONE"}
            </Text>
            {p.children?.length
              ? p.children.map((p, index) => (
                  <View key={index} style={{ paddingBottom: 2 }}>
                    <TextField
                      label="Full name"
                      value={p.fullName || NotApplicable}
                    />
                    <TextField
                      label="Gender"
                      value={p.gender || NotApplicable}
                    />
                    <TextField
                      label="Date of birth"
                      value={transformDate(p.dateOfBirth) || NotApplicable}
                    />
                  </View>
                ))
              : null}

            <Text
              style={{
                fontWeight: 500,
                fontSize: 10,
                color: "#333333",
                maxWidth: 250,
                paddingTop: 8,
                paddingBottom: 2,
                textTransform: "uppercase",
              }}
            >
              Personal Questions:
            </Text>
            <View
              style={{
                fontSize: 11,
                fontWeight: 400,
                color: "#333333",
              }}
            >
              <Text style={{ maxWidth: 250, fontWeight: 500 }}>
                1. Have you ever had serious illness or injury which interrupted
                your schooling? Please explain.
              </Text>
              <Text style={{ maxWidth: 250 }}>
                --- {p.seriousIllnessExplaination}
              </Text>
            </View>

            <View
              style={{
                paddingTop: 8,
                fontSize: 11,
                fontWeight: 400,
                color: "#333333",
              }}
            >
              <Text style={{ maxWidth: 250, fontWeight: 500 }}>
                2. Have you ever been refused admission by or dismissed from a
                theological or other schools?
              </Text>
              <Text style={{ maxWidth: 250 }}>
                --- {p.refusedAdmissionExplaination}
              </Text>
            </View>
          </Header>

          <Header heading="Purpose in Seminary">
            <Text
              style={{
                fontWeight: 500,
                fontSize: 10,
                color: "#333333",
                maxWidth: 250,
                paddingBottom: 2,
                textTransform: "uppercase",
              }}
            >
              Question:
            </Text>
            <View
              style={{
                fontSize: 11,
                fontWeight: 400,
                color: "#333333",
              }}
            >
              <Text style={{ maxWidth: 250, fontWeight: 500 }}>
                What is your desired ministry (indicate order of preference:
                1,2,etc... 1 being the most preferred).
              </Text>
              <Text style={{ maxWidth: 250 }}>
                ---&nbsp;
                {p.otherMinistry
                  ? p.desiredMinistry.concat(p.otherMinistry).join(", ")
                  : p.desiredMinistry.join(", ")}
              </Text>
            </View>
          </Header>
        </View>
      </View>
    </Page>

    <Page size={{ width: 612, height: 936 }} style={styles.page}>
      <PageHeader
        heading="S.E.E Program Application Form"
        rightImageSrc={chedLOGO}
      />
      <View style={styles.content2}>
        <Header heading="Education Information" flexDirection="row">
          <Text
            style={{
              fontSize: 10,
              fontWeight: 500,
              textTransform: "uppercase",
              color: "#333333",
              paddingBottom: 2,
            }}
          >
            High School:
          </Text>
          <View
            style={{
              flexDirection: "row",
              flexWrap: "wrap",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <TextField
              label="School name"
              value={p.jhSchoolName}
              maxWidth={320}
            />
            <TextField label="Year graduated" value={p.jhYearGraduated} />
            <TextField
              label="School address"
              value={p.jhSchoolAddress}
              maxWidth={320}
            />
          </View>

          <Text
            style={{
              fontSize: 10,
              fontWeight: 500,
              textTransform: "uppercase",
              color: "#333333",
              paddingTop: 4,
              paddingBottom: 2,
            }}
          >
            College:
          </Text>
          {p.colleges.map((props, index) => (
            <View
              key={index}
              style={{
                paddingBottom: 2,
                flexWrap: "wrap",
                flexDirection: "row",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <TextField
                label="School name"
                value={props.schoolName}
                maxWidth={320}
              />
              <TextField
                label="Year graduated"
                value={props.yearGraduated}
                maxWidth={320}
              />
              <TextField
                label="Course taken"
                value={props.courseTaken}
                maxWidth={320}
              />
            </View>
          ))}
        </Header>

        <Header heading="Family and Ministry">
          <SubHeader
            subHeading="Home and religious background"
            maxWidth="100%"
            paddingTop={2}
          >
            <Text>{p.religiousBackground}</Text>
          </SubHeader>

          <SubHeader subHeading="Call to the ministry" maxWidth="100%">
            <Text>{p.callToMinistry}</Text>
          </SubHeader>

          <SubHeader subHeading="Experience in the ministry" maxWidth="100%">
            <Text>{p.experienceInMinistry}</Text>
          </SubHeader>

          <SubHeader
            subHeading="Reasons for desiring theological education"
            maxWidth="100%"
          >
            <Text>{p.reasonsForEnrolling}</Text>
          </SubHeader>
        </Header>

        <Header heading="Financial Arrangements">
          <SubHeader
            subHeading="How do you plan to support your seminary education?"
            maxWidth="100%"
            paddingTop={2}
          >
            <Text>{p.supportPlan}</Text>
          </SubHeader>
        </Header>
      </View>
    </Page>
  </Document>
);

Font.register({
  family: "Lora",
  fonts: [
    {
      src: Lora700,
      fontWeight: 700,
    },
  ],
});

Font.register({
  family: "Roboto",
  fonts: [
    {
      src: Roboto400,
      fontWeight: 400,
    },
    {
      src: Roboto500,
      fontWeight: 500,
    },
    {
      src: Roboto700,
      fontWeight: 700,
    },
  ],
});

const styles = StyleSheet.create({
  viewer: {
    width: "100%",
    height: "100vh",
  },
  page: {
    padding: "32 40",
    lineHeight: "1.7",
  },
  content: {
    fontFamily: "Roboto",
    fontWeight: 400,
    flexDirection: "row",
  },
  contentContainer: {
    flexGrow: 1,
  },
  content2: {
    fontFamily: "Roboto",
    fontWeight: 400,
  },
  wrapper: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: "wrap",
    paddingBottom: 2,
  },
});

export default SEEProgramDocument;
