import React from "react";
import ReactDOM from "react-dom";
import App from "./App";

import "rodal/lib/rodal.css";
import "./styles/index.css";
import "./extensions";

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
