import React from "react";
import { View, Text } from "@react-pdf/renderer";

type Props = {
  subHeading: string;
  maxWidth?: string | number;
  paddingTop?: string | number;
};

export const SubHeader: React.FC<Props> = ({
  subHeading,
  maxWidth,
  paddingTop,
  children,
}) => {
  return (
    <View
      style={{
        paddingTop: paddingTop || 8,
        fontSize: 11,
        color: "#333333",
        maxWidth: maxWidth ? maxWidth : "100%",
      }}
    >
      <Text style={{ fontWeight: 500 }}>{subHeading}:</Text>
      {children}
    </View>
  );
};

export default SubHeader;
