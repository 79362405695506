import React from "react";
import {
  Document,
  Page,
  View,
  Text,
  Font,
  StyleSheet,
} from "@react-pdf/renderer";
import { PageHeader, Header, TextField } from "./components";
import {
  EnrolleeStatusProps,
  PersonalProps,
  EducationalProps,
  PaymentProps,
  LearningProps,
} from "routes";

import depedLOGO from "assets/images/deped.png";

import Lora700 from "assets/fonts/lora/lora-v15-latin-700.woff";
import Roboto400 from "assets/fonts/roboto/roboto-v20-latin-regular.woff";
import Roboto500 from "assets/fonts/roboto/roboto-v20-latin-500.woff";
import Roboto700 from "assets/fonts/roboto/roboto-v20-latin-700.woff";

type Props = {} & EnrolleeStatusProps &
  PersonalProps &
  EducationalProps &
  LearningProps &
  PaymentProps;

const BasicEdDocument: React.FC<Props> = (p) => (
  <Document title="Basic Education Admission Form">
    <Page size={{ width: 612, height: 936 }} style={styles.page}>
      <PageHeader
        heading="Basic Ed Department | Admission Form"
        rightImageSrc={depedLOGO}
      />
      <View style={styles.content}>
        <View style={{ ...styles.contentContainer, marginRight: 20 }}>
          <Header heading="Enrollee Status">
            <TextField label="Student Type" value={p.typeOfStudent} />
            <TextField
              label="School Year"
              value={`${p.schoolYear.from} - ${p.schoolYear?.to}`}
            />
            <TextField
              label="Last S.Y. Attended"
              value={`${p.lastSyFromYear} - ${p.lastSyToYear}`}
            />
            <TextField label="Education Level" value={p.educationLevel} />
            <TextField label="Grade Level" value={p.gradeLevel} />
            <TextField label="Academic Track" value={p.academicTrack} />
          </Header>

          <Header heading="Educational Background">
            <TextField label="Elementary" value={p.elementarySchoolName} />
            <TextField
              label="Year Graduated"
              value={p.elementaryYearGraduated}
            />
            <TextField label="Junior High" value={p.jhSchoolName} />
            <TextField label="Year Graduated" value={p.jhYearGraduated} />
            <View
              style={{
                paddingTop: 8,
                fontSize: 11,
                fontWeight: 500,
                color: "#333333",
              }}
            >
              <Text>Authorized Person/s to have an access</Text>
              <Text>on your School Records:</Text>
            </View>
            <TextField label="Name" value={p.authorizedPerson1Name} />
            <TextField
              label="Relationship"
              value={p.authorizedPerson1Relationship}
            />
            <TextField label="Name" value={p.authorizedPerson2Name} />
            <TextField
              label="Relationship"
              value={p.authorizedPerson2Relationship}
            />
          </Header>

          <Header heading="Learning Options">
            <TextField label="Preferred" value={p.learningOption} />
          </Header>

          <Header heading="Payment Information">
            <TextField
              label="Payment Method"
              value={p.paymentMethod || p.paymentService || p.paymentBank}
            />
          </Header>
        </View>

        <View style={styles.contentContainer}>
          <Header heading="Personal Information">
            <TextField label="First Name" value={p.firstName} />
            <TextField label="Last Name" value={p.lastName} />
            <TextField label="Middle Name" value={p.middleName} />
            <TextField label="Suffix" value={p.suffix} />
            <TextField label="Gender" value={p.gender} />
            <TextField label="Birth Place" value={p.birthPlace} />
            <TextField label="Birth Date" value={p.birthDate} />
            <TextField label="Age" value={p.age} />
            <TextField label="Nationality" value={p.nationality} />
            <TextField label="Dialect" value={p.dialect} />
            <TextField label="Ethnic Affliation" value={p.ethnicAffiliation} />
            <TextField label="Religion" value={p.religion} />
            <TextField label="Contact #" value={p.contactNumber} />
            <TextField label="Email Address" value={p.emailAddress} />
            <TextField label="Facebook Account" value={p.facebookAccount} />
            <TextField
              label="Home Address"
              value={
                p.homeCountryAddress
                  ? `${p.homeCountryAddress}, ${p.homeAddress}`
                  : p.homeAddress
              }
            />
            <TextField
              label="Parent's Annual Income"
              value={p.parentsAnnualIncome.annualIncome}
            />
            <TextField
              label="Other Income [Please specify]"
              value={p.parentsAnnualIncome.otherIncome}
            />
            <TextField
              label="Father's Name"
              value={p.parentsInfo.fathersName}
            />
            <TextField
              label="Occupation"
              value={p.parentsInfo.fathersOccupation}
            />
            <TextField
              label="Mother's Name"
              value={p.parentsInfo.mothersName}
            />
            <TextField
              label="Occupation"
              value={p.parentsInfo.mothersOccupation}
            />
            <TextField label="Scholarship" value={p.scholarship} />
            <TextField label="Amount" value={p.scholarshipAmount} />
          </Header>
        </View>
      </View>
    </Page>
  </Document>
);

Font.register({
  family: "Lora",
  fonts: [
    {
      src: Lora700,
      fontWeight: 700,
    },
  ],
});

Font.register({
  family: "Roboto",
  fonts: [
    {
      src: Roboto400,
      fontWeight: 400,
    },
    {
      src: Roboto500,
      fontWeight: 500,
    },
    {
      src: Roboto700,
      fontWeight: 700,
    },
  ],
});

const styles = StyleSheet.create({
  page: {
    padding: "32 40",
    lineHeight: "1.7",
  },
  content: {
    fontFamily: "Roboto",
    fontWeight: 400,
    flexDirection: "row",
  },
  contentContainer: {
    flexGrow: 1,
  },
});

export default BasicEdDocument;
