import React from "react";
import { View, Text } from "@react-pdf/renderer";

type Props = {
  label: string;
  value?: string;
  maxWidth?: string | number;
};

export const TextField: React.FC<Props> = ({ label, value, maxWidth }) => {
  return (
    <View
      style={{
        fontSize: 11,
        color: "#333333",
        flexDirection: "row",
      }}
    >
      <Text>{label}:&nbsp;</Text>
      <Text
        style={{
          fontWeight: 500,
          flexWrap: "wrap",
          maxWidth: maxWidth ? maxWidth : 190,
        }}
      >
        {value}
      </Text>
    </View>
  );
};
