import styled from "styled-components/macro";
import {
  StyledPersonalFieldWrapper,
  StyledEducationalFieldWrapper,
  StyledTestimonyFieldWrapper,
} from ".";
import { StyledFormInputWrapper } from "styles";
import media from "styles/media";

export const StyledRegistrationInfoWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 2.4rem;

  #master-semester {
    grid-column: 1 / 2;
  }

  #master-year-level {
    grid-column: 1 / 2;
  }

  #see-desired-program {
    grid-column: 1 / 3;
  }

  #see-center-location {
    grid-column: 1 / -1;
  }

  #see-center-leader {
    grid-column: 1 / 3;
  }

  #master-status {
    grid-row: 1 / 2;
    grid-column: 2 / -1;
  }

  #master-other-status {
    grid-row: 2 / 3;
    grid-column: 2 / -1;
  }

  ${media.tabletXl} {
    grid-template-columns: 1fr;

    #master-semester {
      grid-column: 1 / -1;
    }

    #master-year-level {
      grid-column: 1 / -1;
    }

    #master-status {
      grid-row: auto;
      grid-column: 1 / -1;

      margin-top: 2rem;
    }

    #master-other-status {
      grid-row: auto;
      grid-column: 1 / -1;
    }
  }

  #master-year-level,
  #master-other-status {
    ${StyledFormInputWrapper} {
      ${media.tabletSm} {
        grid-template-columns: repeat(2, 1fr);
        column-gap: 1.6rem;
      }
    }
  }
  #master-other-status {
    ${StyledFormInputWrapper} {
      ${media.mobileMd} {
        grid-template-columns: 1fr;
      }
    }
  }
`;

export const StyledApplicationInfoWrapper = styled(StyledPersonalFieldWrapper)`
  ${media.laptopXs} {
    grid-template-columns: 1fr;
  }

  #birth-info {
    ${StyledFormInputWrapper} {
      ${media.tabletLg} {
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }

  #address-info {
    ${media.laptopXs} {
      grid-column: 1 / -1;
    }
  }

  #nationality-info {
    grid-row: 3 / 4;
    grid-column: 2 / -1;

    ${media.laptopXs} {
      grid-row: auto;
      grid-column: 1 / -1;

      ${StyledFormInputWrapper} {
        grid-template-columns: repeat(3, 1fr);
      }
    }

    ${StyledFormInputWrapper} {
      ${media.tabletLg} {
        grid-template-columns: repeat(2, 1fr);
      }

      ${media.tabletSm} {
        column-gap: 1.6rem;
      }
    }
  }

  #contact-information {
    ${StyledFormInputWrapper} {
      ${media.tabletLg} {
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }
`;

export const StyledChurchInfoWrapper = styled.div`
  display: grid;
  row-gap: 2rem;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 2.4rem;

  ${media.laptopXs} {
    grid-template-columns: 1fr;
  }

  #church-information {
    grid-column: 1 / -1;

    #church-name {
      grid-column: 1 / 3;
    }

    #church-address {
      grid-row: 2 / 3;
      grid-column: 1 / 4;
    }

    #church-pastor {
      grid-column: 4 / 6;
    }

    ${StyledFormInputWrapper} {
      ${media.laptopXs} {
        grid-template-columns: repeat(3, 1fr);

        #church-name {
          grid-column: 1 / 3;
        }

        #church-address {
          grid-column: 1 / -1;
        }

        #church-pastor {
          grid-column: 1 / 3;
        }
      }

      ${media.tabletSm} {
        grid-template-columns: repeat(2, 1fr);

        #church-name {
          grid-column: 1 / -1;
        }

        #church-address {
          grid-column: 1 / -1;
        }

        #church-pastor {
          grid-column: 1 / -1;
        }
      }

      ${media.tabletSm} {
        column-gap: 1.6rem;
      }
    }
  }

  #church-question-wrapper {
    display: grid;
    row-gap: 1.6rem;

    font-family: Roboto;
    font-weight: 500;
    text-transform: uppercase;

    & p {
      color: var(--color-heading-text);
    }

    #other-affiliation-wrapper {
      ${StyledFormInputWrapper} {
        row-gap: 1.2rem;

        ${media.tabletMd} {
          grid-template-columns: 1fr;
        }
      }
    }

    #other-affiliation {
      grid-column: 1 / 2;
    }
  }
`;

export const StyledPurposeWrapper = styled.div`
  display: grid;
  row-gap: 0.8rem;

  p {
    font-size: 1.5rem;
  }

  .desired-ministry {
    display: flex;
    flex-wrap: wrap;

    & > label:not(:last-of-type) {
      margin-right: 2rem;
      width: max-content;
    }
  }

  .error {
    color: var(--color-primary);
    font-family: Roboto;
    font-weight: 500;
    text-transform: uppercase;
  }

  #other-ministry {
    width: 33%;

    ${media.tabletSm} {
      width: 100%;
    }
  }
`;

export const StyledPersonalInforWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 2rem;
  column-gap: 2.4rem;

  ${media.laptopXs} {
    grid-template-columns: 1fr;
  }

  #full-name {
    #suffix {
      width: 50%;
    }

    ${StyledFormInputWrapper} {
      ${media.tabletSm} {
        grid-template-columns: repeat(2, 1fr);
        column-gap: 1.6rem;
      }

      ${media.mobileMd} {
        grid-template-columns: 1fr;

        #suffix {
          width: 48%;
        }
      }
    }
  }

  #marriage-info {
    ${StyledFormInputWrapper} {
      ${media.tabletSm} {
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }

  #children-info {
    grid-column: 1 / -1;

    .child-full-name {
      grid-column: 1 / 3;
    }

    ${StyledFormInputWrapper} {
      ${media.laptopXs} {
        grid-template-columns: repeat(3, 1fr);
      }

      ${media.tabletSm} {
        grid-template-columns: repeat(2, 1fr);
        column-gap: 1.6rem;
      }
    }
  }

  #personal-questions {
    grid-column: 1 / -1;

    label,
    textarea::placeholder {
      text-transform: unset !important;
    }

    ${StyledFormInputWrapper} {
      ${media.laptopXs} {
        grid-template-columns: 1fr;
      }
    }
  }

  .add-button {
    grid-column: 1 / 2;
  }
`;

export const StyledEducationInfoWrapper = styled(StyledEducationalFieldWrapper)`
  #high-school-name,
  .college-school-name {
    grid-column: 1 / 4;
  }

  #high-school-address,
  .college-course-taken {
    grid-column: 4 / 6;
  }

  p {
    margin-top: 1.2rem;
    margin-bottom: -1.6rem;
    font-size: 1.5rem;
  }

  #junior-high-school {
    ${StyledFormInputWrapper} {
      ${media.laptopXs} {
        grid-template-columns: repeat(3, 1fr);

        #high-school-name {
          grid-column: 1 / 3;
        }

        #high-school-address {
          grid-column: auto;
        }
      }

      ${media.tabletLg} {
        #high-school-name {
          grid-column: 1 / -1;
        }

        #high-school-address {
          grid-column: 1 / 3;
        }
      }

      ${media.tabletSm} {
        grid-template-columns: repeat(2, 1fr);
        column-gap: 1.6rem;

        #high-school-address {
          grid-column: 1 / -1;
        }
      }
    }
  }

  #college-attended {
    ${StyledFormInputWrapper} {
      ${media.laptopXs} {
        grid-template-columns: repeat(3, 1fr);

        .college-school-name {
          grid-column: 1 / 3;
        }

        .college-course-taken {
          grid-column: auto;
        }

        .add-button {
          grid-column: 1 / 2;
        }

        .remove-button {
          grid-column: 2 / 3;
        }
      }

      ${media.tabletLg} {
        .college-school-name {
          grid-column: 1 / -1;
        }

        .college-course-taken {
          grid-column: 1 / 3;
        }
      }

      ${media.tabletSm} {
        grid-template-columns: repeat(2, 1fr);
        column-gap: 1.6rem;

        .college-course-taken {
          grid-column: 1 / -1;
        }
      }
    }
  }

  ${media.laptopXs} {
    grid-template-columns: 1fr;
  }
`;

export const StyledMinistryInfoWrapper = styled.div`
  display: grid;
  row-gap: 1.6rem;

  p {
    font-size: 1.5rem;
  }

  ${StyledTestimonyFieldWrapper} {
    ${media.laptopXs} {
      grid-template-columns: 1fr;
    }
  }
`;

export const StyledFinancialWrapper = styled.div`
  label,
  textarea::placeholder {
    text-transform: unset !important;
  }
`;
